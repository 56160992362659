.div-sidebar {
    position: fixed !important;
    top: 0;
    left: 0;
    background: #e26726;
    color: white;
    font-size: 15px !important;
    border: none;
}

.nav {
    border: none;
}

.nav .nav-item {
    border: none;
}

.nav .nav-item .nav-link {
    color: white;
    font-weight: 400;
    border: none;
    text-decoration: none;
    width: 180px;
}

.nav .nav-item .nav-link:hover {
    background: white;
    color: black;
    font-weight: 400;
    border: none;
    text-decoration: none;
    border-radius: 15px;
}

.nav .nav-item .nav-link:focus {
    background: white;
    color: black;
    font-weight: 400;
    border: none;
    text-decoration: none;
    border-radius: 15px;

}