.d-flex {
    display: flex;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .me-3 {
    margin-right: 1rem;
  }
  
  .inp {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  
  .datepicker-container {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .select-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
  
  .year-select, .month-select {
    width: 48%;
    padding: 0.25rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
  }
  