.large-input {
    font-size: 1.2rem;
    padding: 10px;
    width: 545px;

  }
  
  .large-input::placeholder {
    color: #888888; 
  }
  .custom-button {
    background-color: #FF8C00;
    border-color: #FF8C00;
    color: white;
  }
  .radio{
    color: #E07C00;
  }
  
  .custom-button:hover {
    background-color: #E07C00; 
    border-color: #E07C00;
  }
  .BG{
    background-color: #F5F7FA;
  }
  .b{
    margin-left: 25px;
  }
  .text{
    color: #93570d;
  }
  .radio-selected {
  color: darkorange;
}
.InputGroup{
  margin-left: 50px;
}
.no-border-input {
  border: none;
  box-shadow: none;
}

.custom-radio [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}


.custom-radio [type="radio"] + label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  color: #000; 
}

.custom-radio [type="radio"] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid darkorange;
  border-radius: 50%;
  background: transparent;
}

.custom-radio [type="radio"]:checked + label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: darkorange;
}
