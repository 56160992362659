.form .form-input {
    /* background: ; */
    width: 230px;
    background-color: rgba(249, 248, 248, 1) !important;
    border-radius: 50px;
    border: none;
}

.carte-dashboard {
    border: none;
    border-radius: 15px;
}

.graph-container {
    width: 1200px !important;
    height: 550px !important;
    margin: 0 auto;
}

.content-graph {
    width: 100%;
    height: 300px !important;
}
.cards-container {
    margin-right: 200px; 
  }
  