.input-vendeur {
    border: none !important;
    background: #FFFFFF;

}

.input-icon {
    top: 4px !important;
    font-size: 18px;
    color: black;
    left: 55px !important;
    z-index: 1;
}

.btn-vendeur {
    background: #F46425;
    color: #FFFF;
    font-size: 13px;
    border-radius: 5px !important;
}

.btn-vendeur:hover {
    background: #F46425;
    color: #FFFF;
    font-size: 13px;
    border-radius: 5px !important;
}

.icon {
    color: #FFA73C !important;
    font-size: 20px !important;
}

.action1 {
    width: 35px !important;
    border-radius: 50% !important;
    background: #f0d9bd;
    color: #FFA73C !important;
}

.action2 {
    width: 35px !important;
    border-radius: 50% !important;
    background: #edbebe;
    color: #FA1414 !important;
}

.btn-form {
    background: linear-gradient(307.78deg, #DB242A 4.2%, #E58924 64.82%);
    color: #FFFF;

}

.btn-form:hover {
    background: linear-gradient(307.78deg, #DB242A 4.2%, #E58924 64.82%);
    color: #FFFF;

}